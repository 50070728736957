@import url("https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grape+Nuts&family=Roboto:ital,wght@0,100;0,300;0,400;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  padding-left: 0.5rem;
}

.marginXAuto {
  margin: 0 auto 0 auto;
}
